<!--
 * @Autor: oops.liu
协议富文本
-->
<template>
  <div class="agreement">
    <scroller>
      <div class="content">
        <van-empty
          :image="require('@/assets/img/address_empty.png')"
          v-if="!content"
          description="暂无内容！！！"
        />
        <template v-else-if="content">
          <div v-html="content"></div>
        </template>
      </div>
    </scroller>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  components: {},
  props: {},
  data() {
    return {
      content: null,
    };
  },
  computed: {
    type() {
      return this.$route.params.type;
    },
  },
  methods: {
    ...mapActions({ load: "agreement/load" }),
    async loadInfo() {
      const info = await this.load({ type: this.type });
      this.content = info.content;
    },
  },
  created() {
    this.loadInfo();
  },
};
</script>

<style scoped lang="scss">
.agreement {
  width: 100%;
  height: 100%;
  background: #f2f2f2;
  ::v-deep .content {
    box-sizing: border-box;
    padding: 15px;
    p {
      margin: 0;
      padding: 0;
    }
    h2 {
      font-weight: normal;
    }
    h3 {
      font-weight: normal;
    }
    h4 {
      font-weight: normal;
    }
  }
}
</style>
